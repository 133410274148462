<template>
    <div>
        <NavBar
            style="max-height: 50px; margin-bottom: 10px"
            :username="currentUserName"
            :pageName="pageName"
        >
        </NavBar>

        <v-app>
            <div style="background-color: #f5faff; height: 94.5vh !important">
                <v-row cols="12">
                    <v-col cols="2">
                        <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
                    </v-col>
                    <v-col cols="10">
                        <div style="margin: 40px 40px 0px 40px;">
                            <v-row style="margin-left: 5px" class="pageHeaderClass">
                                <div>
                                    <span
                                    style="font-weight: 800; font-size: 12pt;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;">
                                    <h3>Create Package</h3>
                                    </span>
                                </div>
                            </v-row>
                            <div v-if="!isLoading" class="mt-5">
                                <v-card class="pa-4 d-flex flex-column text-left overflow-auto" height="80vh">
                                    <div>
                                        <p>Package Name<span class="RequiredField">*</span> </p>
                                        <v-text-field v-model="packageName" style="width: 35%" solo
                                        :rules="[v=> (v==='' ? 'Required':false),]"
                                        ></v-text-field>
                                    </div>
                                    <div v-if ="listOfCompany.length > 0">
                                        <p>Company Name <span class="RequiredField">*</span></p>
                                        <v-select v-model="companyNameId"
                                            :items="listOfCompany"
                                            item-text="company_name"
                                            item-value="_id"
                                            placeholder="Select Company linked to package"
                                            style="width: 35%"  solo
                                            :rules="select_rules"
                                        >
                                        </v-select>
                                    </div>
                                    <div v-else>
                                        No Corporates found, please create a corporate before creating packages.
                                    </div>
                                    <div>
                                        <p>Description <span class="RequiredField">*</span></p>
                                        <v-textarea v-model="packageDescription"
                                            style="width: 35%" 
                                            solo
                                            placeholder="Enter the package Description"
                                            :rules="[v=> !!v || 'Required',]"
                                        >
                                        </v-textarea>
                                    </div>
                                    <div>
                                        <p>Description (Burmese)<span class="RequiredField">*</span></p>
                                        <v-textarea v-model="packageDescriptionMM"
                                            style="width: 35%;"
                                            solo
                                            placeholder="Enter the package Burmese Description"
                                            :rules="[value=> !!value || 'Required',]"
                                        ></v-textarea>
                                    </div>
                                    <div>
                                        <p>Fair Usage Policy (FUP) <span class="RequiredField">*</span></p>
                                        <v-radio-group row v-model="packageFUP">
                                            <v-radio label="Yes" value="1" class="radioButton"></v-radio>
                                            <v-radio label="No" value="2" class="radioButton"></v-radio>
                                        </v-radio-group>

                                        <div v-if="packageFUP == 1">
                                            <div>
                                                <p>Specialization <span class="RequiredField">*</span></p>
                                                <v-select
                                                    v-model="selected_specialization"
                                                    :items = "specialization_list"
                                                    item-text = "specialization_name"
                                                    item-value = "_id"
                                                    multiple
                                                    :rules="select_rules"
                                                    style="width: 35%" solo>
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            ripple
                                                            @click="toggleSpecialization"
                                                            >
                                                            <v-list-item-action>
                                                                <v-icon :color="selected_specialization.length > 0 ? 'indigo darken-4' : ''">
                                                                    {{ iconSpec }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ selectAllSpecText }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 5">
                                                            <span>{{ item.specialization_name }}</span>
                                                        </v-chip>
                                                        <span
                                                            v-if="index === 5"
                                                            class="grey--text text-caption"
                                                            >
                                                            (+{{ selected_specialization.length - 5 }} others)
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div>
                                                <p>Type of Discount <span class="RequiredField">*</span></p>
                                                <v-select  :rules="select_rules" v-model="selected_promotion_type" :items="listOfPromotionTypes" style="width: 35%"  solo>
                                                </v-select>
                                            </div>
                                            <div class="d-flex">
                                                <div>
                                                    <p>Discount<span class="RequiredField">*</span></p>
                                                    <v-text-field 
                                                        solo
                                                        v-model="discount"
                                                        :suffix="selected_promotion_type == 'PERCENTAGE_OFF' ?  '%' : ''"
                                                        :prefix="selected_promotion_type == 'FLAT_OFF' ? 'MMK' : ''"
                                                        placeholder="Discount value"
                                                        :rules="discount_rules"
                                                        >
                                                    </v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div>
                                                    <p>Minimum spend<span class="RequiredField">*</span></p>
                                                    <v-text-field
                                                        v-model="minimum_spend"
                                                        prefix="MMK"
                                                        style="width: 80%"
                                                        solo
                                                        placeholder="0 if no amount"
                                                        :rules="min_spend_rules"
                                                        >
                                                    </v-text-field>
                                                </div>
                                                <div v-if="selected_promotion_type == 'PERCENTAGE_OFF' && parseInt(discount) < 100">
                                                    <p>Maximum Discount amount</p>
                                                    <v-text-field 
                                                        v-model="max_discount_amount"
                                                        solo
                                                        prefix="MMK"
                                                        placeholder="Blank if no max amount"
                                                        :rules="max_discount_rules"
                                                        >
                                                    </v-text-field>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="font-weight-bold">Frequency<span class="RequiredField">*</span></p>
                                            </div>
                                            <div class="d-flex">
                                                <div>
                                                    <p>Booking Count<span class="RequiredField">*</span></p>
                                                    <v-text-field :rules="frequency_rules" v-model="frequency_count" style="width: 80%" solo placeholder="Frequency Count">
                                                    </v-text-field>
                                                </div>
                                                <div>
                                                    <p>Number Of Days<span class="RequiredField">*</span></p>
                                                    <v-text-field :rules="frequency_rules" v-model="frequency_days" style="width: 80%" solo placeholder="Frequency Days">
                                                    </v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex allowDependantSwitch"> 
                                                <div>
                                                <p class="font-weight-bold">Allow dependant booking<span class="RequiredField">*</span></p>
                                                </div>
                                                <v-switch
                                                v-model="isAllowDependant"
                                                ></v-switch>
                                            </div>
                                            <div>
                                                <p class="font-weight-bold">Max Redemption Count Per User</p>
                                                <v-text-field v-model="max_redemption_count" style="width: 50%" solo placeholder="Max Redemption Count">
                                                </v-text-field>
                                            </div>
                                        </div>
                                    </div>

                                    <br>

                                    <div>
                                        <div style="width: 35%;">
                                            <div style="text-align:left">
                                                <p >Banner</p>
                                                <p >Recommended size: <b>1029 x 335</b></p>
                                            </div>
                                        </div>
                                        <div style="width: 35%;">
                                            <div>
                                            <v-file-input @change="getPics" v-model="uploadedBanner" accept="image/jpeg,image/png" />
                                            </div>
                                            <div v-if="imageURL">
                                            <img height="100px" width="200px" :src = "imageURL" />
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                            <div v-else>
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>
                        </div>
                        <div style="margin: 10px;">
                            <v-btn
                                color="primary"
                                style="width:20%;background-color: #1467BF;"
                                @click="redirectToCreatBenefits"
                                v-if="!isLoading"
                                :disabled = "isButtonClicked || checkSaveDisabled()"
                                :loading = "isButtonClicked"
                                >
                                save
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    import NavBar from "../../../views/navbar.vue";
    import SideBar from "../../../views/sideBar.vue";
    import { handleError } from '../../../utils/utils';
  
    export default{
      name: "CreateCorporate",
      data() {
          return{
              pageName: "",
              currentUser: "",
              currentUserName: "",
              packageName:"",
              listOfCompany:[],
              packageDescription:"",
              packageDescriptionMM:"",
              companyNameId:"",
              packageID:"",
              isButtonClicked: false,
              packageFUP: "2",
              specialization_list: [],
              selected_specialization: [],
              isLoading: true,
              listOfPromotionTypes:['PERCENTAGE_OFF', 'FLAT_OFF'],
              selected_promotion_type: '',
              discount: null,
              minimum_spend: null,
              frequency_count: null,
              frequency_days: null,
              max_discount_amount: null,
              discount_rules: [
                v => !!v || 'Discount is required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <= 0) || 'Must be a positive number',
                v => !(this.selected_promotion_type === 'PERCENTAGE_OFF' && Number(v) >100) || 'Always less than 100%'
              ],
            min_spend_rules: [
                v => !!v || 'Minimum spend is required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <0) || 'Must be greater than or equal to zero',
              ],
            max_discount_rules: [
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <0) || 'Must be greater than or equal to zero',
            ],
            frequency_rules: [
                v => !!v || 'Required',
                v => !isNaN(v) || 'Must be a number',
                v => !(Number(v) <=0) || 'Must be greater than zero',
            ],
            select_rules: [
                v => !(v.length <= 0) || 'Select atleast one value',
            ],
            imageURL: "",
            uploadedBanner: null,
            attachmentFiles: null,
            fileTypes: ["image/jpeg", "image/png"],
            sampleFileLists:[],
            filesArray:[],
            isAllowDependant: false,
            max_redemption_count: ''
          }
      },
      components: {
        NavBar,
        SideBar,
      },
        mounted(){
            this.pageName = "CreateCorparatePackage";
            this.currentUser = this.$cookies.get("token");
            if (!this.currentUser) {
                this.$router.push({
                    name: "AdminLogin",
                });
            } else {
                var readAllCorporateMasterBody = {
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                }
                var getAdminBody = {
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                    adminType : "ADMIN"
                };
                axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAdminBody)
                .then((getAdminResponse) => {
                })
                .catch((getAdminError) => {
                    if (getAdminError.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$router.push({
                            name: "AdminLogin"
                        });
                    }
                    else {
                        window.alert("Something went wrong...");
                    }
                });
                axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllCorporateMasters", readAllCorporateMasterBody)
                .then((readAllCorporateMasterResponse) => {
                    this.listOfCompany = readAllCorporateMasterResponse.data.data;
                    this.specialization_list = readAllCorporateMasterResponse.data.specialization_list;
                    this.isLoading = false;
                })
                .catch((readAllCorporateMasterError)=>{
                    console.log("readAllCorporateMasterError",readAllCorporateMasterError);
                });
            }
        },
        computed: {
            selectAllSpecDisable () {
                return this.selected_specialization.length === this.specialization_list.length
            },
            selectParticularSpecialization () {
                return this.selected_specialization.length > 0
            },
            iconSpec () {
                if (this.selectAllSpecDisable) return 'mdi-close-box';
                if (this.selectParticularSpecialization) return 'mdi-minus-box';
                return 'mdi-checkbox-blank-outline';
            },
            selectAllSpecText() {
                return this.selected_specialization.length === this.specialization_list.length ? 'Unselect All' : 'Select All'
            }
        },
        methods:{
            toggleSpecialization() {
                if (this.selectAllSpecDisable) {
                    this.selected_specialization = [];
                } else {
                    this.selected_specialization = this.specialization_list.slice().map( (x) => {
                        return x._id;
                    });
                }
            },
            uuidv4() {
                return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },
            getPics() {
                this.attachmentFiles = null;
                if (this.uploadedBanner) {
                    if (this.fileTypes.includes(this.uploadedBanner.type)) {
                        //giving the BLOB a structure
                        let reader = new FileReader();
                        reader.readAsDataURL(this.uploadedBanner);
                        reader.onload = function(e) {
                            //converting the structure to image
                            let img = new Image();
                            img.src = e.target.result;
                            img.onload = function (x) {
                                if (img.width==1029 && img.height==335) {
                                    let fileInfo = {
                                        name: this.uploadedBanner.name,
                                        type: this.uploadedBanner.type,
                                        size: Math.round(this.uploadedBanner.size/1000)+' kB',
                                        base64: reader.result,      //converting img to Binary
                                        file: this.uploadedBanner   //BLOB
                                    };
                                    this.attachmentFiles = fileInfo;
                                    this.imageURL = URL.createObjectURL(this.uploadedBanner);
                                } else {
                                    window.alert("Please upload the image with 1029 x 335 dimension");
                                    console.log("Provided dimension: ", this.width, " x ", this.height);
                                    this.uploadedBanner = null;
                                    this.attachmentFiles = null;
                                }
                            }.bind(this);
                        }.bind(this);
                    } else {
                        this.uploadedBanner = null;
                        this.attachmentFiles = null;
                        window.alert("Not a valid file");
                    }
                }
            },
            checkSaveDisabled() {
                return !( ( this.companyNameId && this.packageName.trim() && this.packageDescription.trim() && this.packageDescriptionMM.trim() && this.uploadedBanner ) && ( this.packageFUP != 1 || ( this.packageFUP == 1 && this.selected_specialization.length > 0 && parseInt(this.discount) > 0 && ( ( this.selected_promotion_type == 'PERCENTAGE_OFF' && parseInt(this.discount) <= 100 ) || this.selected_promotion_type == 'FLAT_OFF' ) && parseInt(this.minimum_spend) >= 0 &&  parseInt(this.frequency_count) > 0 && parseInt(this.frequency_days) > 0 && ( !this.max_discount_amount || this.max_discount_amount && parseInt(this.max_discount_amount) > 0) ) ) );
            },
            redirectToCreatBenefits() {
                this.isButtonClicked = true;

                var fileName;
                if(this.uploadedBanner == null) {
                    // console.log("Banner not uploaded!");
                } else {
                    //making the banner ready for S3 uploading
                    fileName = this.uuidv4() + this.uploadedBanner.name.split(" ").join("_").toLowerCase();
                    this.sampleFileLists.push({
                        name: fileName,
                        uploadStatus: "Uploading",
                        size: this.uploadedBanner.size,
                        type: this.uploadedBanner.type
                    })

                    var active_file = this.uploadedBanner;
                    var getPresignedURLBody = {
                        "file_name": fileName,
                        "file_type": active_file.type,
                        "token" : this.currentUser,
                        "typeOfUser" : "ADMIN"
                    }

                    //large file handling
                    if (this.uploadedBanner.size >= 10485760) {
                        this.sampleFileLists.forEach(function(item){
                            if(item.name == fileName){
                            item.uploadStatus = "LargeFile"
                            }
                        });
                    } else { 
                        axios.post(process.env.VUE_APP_BACKEND_URL + "/getPresignedURLWriteForBanner", getPresignedURLBody)
                        .then((getPresignedURLSuccess) => {
                            var fields = getPresignedURLSuccess.data.data.fields;
                            var url = getPresignedURLSuccess.data.data.url;

                            var post_body = {
                                bucket: fields.bucket,
                                ...fields,
                                'Content-Type': active_file.type,
                                file: active_file
                            };

                            const formData = new FormData();
                            for (const name in post_body) {
                                formData.append(name, post_body[name]);
                            }

                            axios.post(url, formData)
                            .then((imageUploadSuccess) => {
                                // console.log("Banner Uploaded successfully ", imageUploadSuccess);

                                var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                                this.sampleFileLists.forEach(function(item){
                                    if(item.name == uploadedFile.name ){
                                        item.uploadStatus = "Completed"
                                        this.filesArray.push(item)
                                    }
                                }.bind(this))
                            }).catch((imageUploadFail) => {
                                console.log("Banner upload Fail", imageUploadFail);

                                var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                                this.sampleFileLists.forEach(function(item){
                                    if(item.name == uploadedFile.name && item.size > 10000000 ){
                                        item.uploadStatus = "LargeFile"
                                    }
                                    else if(item.name == uploadedFile.name) {
                                        item.uploadStatus = "Failed"
                                    }
                                });
                            })
                        }).catch((getPresignedURLError) =>{
                            console.log("getPresignedURLError Error: ", getPresignedURLError);
                        });
                    }
                } 
                

                var CreateCorporatePackageBody = {
                    corporateMasterMapping: this.companyNameId,
                    corporateProgramName: this.packageName.replace(/\s+/g,' ').trim(),
                    description: this.packageDescription.replace(/\s+/g,' ').trim(),
                    descriptionMM: this.packageDescriptionMM.replace(/\s+/g,' ').trim(),
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                    fileName : fileName,
                };
                if ( this.packageFUP == 1 ) {
                    CreateCorporatePackageBody['selected_specialization'] = this.selected_specialization;
                    CreateCorporatePackageBody['discount'] = this.discount;
                    CreateCorporatePackageBody['selected_promotion_type'] = this.selected_promotion_type;
                    CreateCorporatePackageBody['minimum_spend'] = this.minimum_spend;
                    if(this.discount == '100' && this.selected_promotion_type == 'PERCENTAGE_OFF')
                        CreateCorporatePackageBody['max_discount'] = '';
                    else
                        CreateCorporatePackageBody['max_discount'] = this.max_discount_amount ? this.max_discount_amount : ''; 
                    // CreateCorporatePackageBody['max_discount'] = this.max_discount_amount ? this.max_discount_amount : '';
                    CreateCorporatePackageBody['frequency_days'] = this.frequency_days;
                    CreateCorporatePackageBody['frequency_count'] = this.frequency_count;
                    CreateCorporatePackageBody['isAllowDependant'] = this.isAllowDependant;
                    CreateCorporatePackageBody['max_redemption_count'] = this.max_redemption_count;
                }
                axios.post(process.env.VUE_APP_BACKEND_URL + "/createCorporatePackage", CreateCorporatePackageBody)
                .then((createCorporatePackageResponse) => {
                    this.packageID = createCorporatePackageResponse.data.data._id
                    this.$router.push({
                        path: "/admin/AddBenefitsToPackage/"+this.packageID
                    });
                    this.isButtonClicked = false;
                })
                .catch((createCorporatePackageError) => {
                    this.isButtonClicked = false;
                    handleError(createCorporatePackageError, this.$router, 'createCorporatePackageError', 'AdminLogin', 'token');
                });
            },
        }
    }
</script>

<style scoped>
    .createCorporateCard {
      padding: 25px;
      margin-top: 5%;
      width: 80%;
    }
    .createCorporateCardTitle {
      text-align: left;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px
    }
    .RequiredField {
        color: red;
    }
</style>

<style>
    .radioButton .v-icon.v-icon{
        font-size: 18px !important;
    }
    .v-input--selection-controls{
        margin-top: 0px !important;
        padding-top: 2px !important;
    }
    .allowDependantSwitch{
        gap: 8px;
    }
</style>